import React from "react";
import AppBar from "@mui/material/AppBar";
import { styled } from "@mui/system";
import logo from "../assets/Tranvolt_Logo_SVG 1.svg";
import profile from "../assets/profile.svg";
import { Box } from "@mui/material";

const StyledAppBar = styled(AppBar)({
  backgroundColor: "black",
  height: "45px",
  // width: "100%",
  margin: "0 0 -2px 0px",
  paddingX: 0,
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  flexGrow:"grow",
  flexWrap:"wrap"
});

const Header = ({ username }) => {
  return (
    <StyledAppBar position="static">
      <Box paddingX={"10px"} alignSelf={"center"} marginLeft={2}>
        <img src={logo} alt="Logo" width={"70%"} />
      </Box>
      <Box alignSelf={"center"} fontSize={"15px"} fontFamily={`"Roboto", "Helvetica", "Arial", sans-serif`}>
      Mira Bhainder Municipal Corporation (Transport Division)
        </Box>
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        paddingX={"10px"}
        alignSelf={"center"}
        gap={1}
      >
        
        <Box alignSelf={"center"} fontSize={"10px"}>
          info@transvolt.com
        </Box>
        <Box alignSelf={"center"}>
          <img src={profile} alt="Profile" width={"60%"} />
        </Box>
      </Box>
    </StyledAppBar>
  );
};

export default Header;
