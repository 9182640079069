import { useEffect, useRef } from 'react';
import L from 'leaflet';

// Custom hook to manage map bounds
const useFitMapBounds = (busCoordinates, busNo) => {
  // console.log("busNumber",busNo)
  // console.log("busNumber",busCoordinates)

  const mapRef = useRef();

  // Function to fit map bounds to a specific bus
  const fitMapBoundsToBus = (busId) => {
    if (mapRef.current && busCoordinates[busId]) {
      const data = busCoordinates[busId]
      console.log(latitude,"bubusCoordinates[busId]")

      const [latitude, longitude] = data;

      const bounds = L.latLngBounds([[latitude, longitude]]);
      mapRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  };

  // Function to fit map bounds to all buses
  const fitMapBoundsToAllBuses = () => {
    if (mapRef.current && Object.keys(busCoordinates).length > 0) {
      const latLngs = Object.values(busCoordinates).map(([lat, lng]) => [lat, lng]);
      const bounds = L.latLngBounds(latLngs);
      mapRef.current.fitBounds(bounds, { padding: [50, 50] });
    }
  };

  useEffect(() => {
    if (busNo && busCoordinates[busNo]) {
      fitMapBoundsToBus(busNo);
    } else {
      fitMapBoundsToAllBuses(); // Fallback to fitting all buses if specific busNo is not found
    }
  }, [busNo, busCoordinates]);

  return (map) => {
    mapRef.current = map;
  };
};

export default useFitMapBounds;
