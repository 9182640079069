import axios from "axios"; // Import axios for making HTTP requests
import FormData from 'form-data';

// const API_URL = "http://192.168.29.36:8000"; // Base URL for the local

const API_URL = "https://timscan.transvolt.in/api"; // Base URL for the API

const API = axios.create({ baseURL: API_URL }); // Create an axios instance with the base URL

const config = {
  headers: {
    "Content-Type": "application/json", // Default headers for POST requests
  },
};

// Function to login a user and obtain a token
export const loginUser = async (username, password) => {
  try {
    const response = await API.post(
      "/vehical/token/",
      {
        username,
        password,
      },
      config
    );
    return response.data; // Return the response data (token)
  } catch (error) {
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

// Function to fetch the route data
export const fetchRoute = async () => {
  try {
    const response = await API.get("/vehical/Get-RouteNo15");
    return response.data; // Return the route data
  } catch (error) {
    console.error(
      "Error fetching Routes:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

// Function to fetch the bus stops data
export const fetchStops = async () => {
  try {
    const response = await API.get("/vehical/Get-RouteNo15-BusStops");
    return response.data; // Return the bus stops data
  } catch (error) {
    console.error(
      "Error fetching Routes:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

// Function to fetch dashboard details with authentication
export const fetchDashboardDetails = async () => {
  try {
    const authToken = localStorage.getItem("AuthToken"); // Get the auth token from localStorage
    const token = authToken ? JSON.parse(authToken).token : null;
    const response = await API.get("/schedule/get-dashboard-count/", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${token}`, // Set the Authorization header with the token
      },
    });
    console.log("Dashboard:", response.data);
    return response.data; // Return the dashboard details
  } catch (error) {
    console.error(
      "Error fetching dashboard details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

export const getCurrentDate = (separator = "-") => {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();

  return `${year}${separator}${
    month < 10 ? `0${month}` : `${month}`
  }${separator}${date}`;
};

// Function to fetch the list of buses with authentication
export const fetchBusList = async (date,number,dataperPage,currentPage) => {
  try {
    console.log("number",number)
    const authToken = localStorage.getItem("AuthToken"); // Get the auth token from localStorage
    const token = authToken ? JSON.parse(authToken).token : null;
    // const formdata = new FormData();
    // formdata.append("date", date);
    // const params = new URLSearchParams(formdata);

    const response = await API.post(
      "/schedule/GetallBussesListjson/",
      null,
      // formdata,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // Set the Authorization header with the token
          // params,
        
        },params:{
          vehical:number?number:"",
          page:currentPage,
          page_size:dataperPage,
          date:date
        }
        
      }
    );
    return response.data; // Return the list of buses
  } catch (error) {
    console.log(error);
    console.error(
      "Error fetching bus details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

// Function to fetch the list of routes with authentication
export const fetchRouteListold = async () => {
  try {
    const authToken = localStorage.getItem("AuthToken"); // Get the auth token from localStorage
    const token = authToken ? JSON.parse(authToken).token : null;
    const formdata = new FormData();
    // formdata.append("date", "2024-08-21");
    formdata.append("date", getCurrentDate());

    const response = await API.post("schedule/GetRouteListjson/", formdata, {
      headers: {
        // "Content-Type": "application/json",
        Authorization: `Token ${token}`, // Set the Authorization header with the token
      },
    });
    return response.data; // Return the list of routes
  } catch (error) {
    console.error(
      "Error fetching routes details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

export const fetchRouteList = async (date,route_number,dataperPage,currentPage) => {
  try {
    const authToken = localStorage.getItem("AuthToken"); // Get the auth token from localStorage
    const token = authToken ? JSON.parse(authToken).token : null;
    // const formdata = new FormData();
    // formdata.append("date", props);
    // const params = new URLSearchParams(formdata);

    const response = await API.post(
      "/schedule/GetRouteListjson/",
      null,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`, // Set the Authorization header with the token
          // params,
        },params:{
          route_number:route_number?route_number:"",
          page:currentPage,
          page_size:dataperPage,
          date:date
        }
      }
    );
    return response.data; // Return the list of buses
  } catch (error) {
    console.log(error);
    console.error(
      "Error fetching routes details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};

// Function to fetch the list of routes with authentication
export const fetchChargersList = async (date,choice,chargerName,dataperPage,currentPage) => {
  try {
    const authToken = localStorage.getItem("AuthToken"); // Get the auth token from localStorage
    const token = authToken ? JSON.parse(authToken).token : null; 
    // const params = new URLSearchParams(formdata);
    const response = await API.post(
      "/schedule/GetChargersListjson/",
      // formdata,
      null,
      {
        headers: {
          // "Content-Type": "multipart/form-data",
          // ...formdata.getHeaders(),
          Authorization: `Token ${token}`, // Set the Authorization header with the token
          // params,
        },params:{
          charger_name:chargerName?chargerName:"",
          page:currentPage,
          page_size:dataperPage,
          date:date,
          choice:choice
        }
        // data: formdata
      }
    );
    return response.data; // Return the list of buses
  } catch (error) {
    console.log(error);
    console.error(
      "Error fetching Chargers details:",
      error.response?.data || new Error("Network error")
    );
    throw error.response?.data || new Error("Network error"); // Throw an error if the request fails
  }
};