import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Dashboard from "./pages/Layout/Dashboard.js";
import Login from "./components/Login/Login.js";
import LiveBusestableb from "./pages/Buses/LiveBusestableb.js";
import Header from "./components/Header.js";
import LiveChargingtableb from "./pages/Chargers/LiveChargingtableb.js";
import LiveRoutestableb from "./pages/Routes/LiveRoutestableb.js";

function App() {
  const location = useLocation()
  return (
    <>
    <div >
    {location.pathname!=="/"&&<Header />}
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/DeployedBuses" element={<LiveBusestableb />} />
        <Route path="/Charging_Stations" element={<LiveChargingtableb />} />
        <Route path="/Routes" element={<LiveRoutestableb />} />

      </Routes>
    </div>
      
    </>
  
  
  );
}

export default App;
