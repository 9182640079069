import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import './styles/style.css'
import App from "./App";
// import '@nextui-org/react/style.css';


import { BrowserRouter } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
    <App />
    </BrowserRouter>
   
  // </React.StrictMode>
);
